import CryptoJS from "crypto-js";
import Api from "../services/Api";

export default class User {

    // Efetua o login
    static login = async (userData) => {
        const api = new Api();
        let result = null;
        if (userData.id > 0) {
            result = await api.put('users/' + CryptoJS.MD5(userData.email).toString(), userData);
        } else {
            userData.token = CryptoJS.MD5(userData.email).toString();
            result = await api.post('users', userData);
        }
        return result;
    }

    // Check Email
    static getByEmail = async (email) => {
        const api = new Api();
        let result = await api.get('users/' + CryptoJS.MD5(email).toString());
        return result;
    }
}

