import { useState, useEffect } from 'react';
import Api from '../services/Api';
import Global from '../Globals';

function useInstitution() {
    const [institution, setInstitution] = useState(Global.institution);
    useEffect(() => {
        const api = new Api();
        api.get('institutions').then(result => {
            if (result.response.length > 0) {
                setInstitution(result.response[0]);
            }
        });
    }, []);
    return institution;
}

export default useInstitution;